import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/lib/jenkins/workspace/jelly-bnd-prod-scheduled/www/bnd/src/templates/default-markdown/index.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is the official copyright policy (the “Copyright Policy”) for BuyerZone.com LLC. (d/b/a Business.com) or one of its
corporate affiliates (“Business.com”, “us” or “we”), in connection with the websites we operate (each, a “Site”).
Business.com respects the rights of others and we expect users of our websites and services to do the same. This
Copyright Policy prohibits the infringement of the copyrights of others, and it is also our policy that we may remove,
suspend, terminate access, or take other appropriate action against repeat offenders. We may also remove content that in
our sole discretion appears to infringe the intellectual property rights of others. If we remove or disable a user’s
access to our websites or services to comply with the Digital Millennium Copyright Act (DMCA), we will make a good-faith
attempt to contact the owner, author, or administrator of each affected account so that they may make a counter
notification pursuant to sections 512(g)(2) and (3) of that Act.`}</p>
    <p>{`This Copyright Policy is a part of the terms and conditions which are set forth in our `}<a parentName="p" {...{
        "href": "https://www.businessnewsdaily.com/terms-of-use"
      }}>{`Terms of Use`}</a>{` and
Sale (“Terms”). Any terms that are not defined in this Copyright Policy shall have the meaning given in the Terms. Both
the Terms and this Copyright Policy are legally binding on all users.`}</p>
    <p>{`1.1 `}<u>{`How to File an Infringement Notification`}</u>{`. If you have evidence, know, or have a good faith belief that
content residing on or accessible through our websites or Internet services infringes a copyright which you own or for
which you are a designated agent, please send a notice of infringement by fax or regular mail with the information that
sets forth the items specified below.`}</p>
    <p>{`To expedite our ability to process your request, please use the following format:`}</p>
    <ul>
      <li parentName="ul">{`Identify the copyrighted work claimed to have been infringed. If multiple copyrighted works are covered by a single notification, provide a representative list of such works.`}</li>
      <li parentName="ul">{`Identify the material that is claimed to be infringing or to be the subject of infringing activity. Include information reasonably sufficient to permit Business.com to locate the material. Please provide a URL and screenshots for each item. Include the specific asset(s) or page(s) that you claim to be infringing. Say entire work ONLY if all assets / pages in a collection/document are infringing.`}</li>
      <li parentName="ul">{`Include details of your claim to the material, or your relationship to the material’s copyright holder.`}</li>
      <li parentName="ul">{`Provide your full name, address, and telephone number should we need to clarify your claim.`}</li>
      <li parentName="ul">{`Provide a working email address where we can contact you to confirm your claim.`}</li>
      <li parentName="ul">{`If true, include the following statement: “I have a good faith belief that use of the copyrighted materials described above as the allegedly infringing web pages is not authorized by the copyright owner, its agent, or the law.”`}</li>
      <li parentName="ul">{`If true, include the following statement: “I swear, under penalty of perjury, that the information in the notification is accurate and that I am the copyright owner or am authorized to act on behalf of the copyright owner to make this complaint.”`}</li>
      <li parentName="ul">{`Sign the document, physically or electronically.`}</li>
      <li parentName="ul">{`Send the document to Business.com’s designated Copyright Agent to receive notifications of claimed infringement by one of the following means:`}</li>
    </ul>
    <p>{`Business.com Copyright Agent
200 Fifth Avenue
Second Floor
Waltham, MA 02451`}</p>
    <p>{`Or send an email to: `}<a parentName="p" {...{
        "href": "mailto:copyright@business.com"
      }}>{`copyright@business.com`}</a></p>
    <p>{`We will review your claim, determine its merit, and act accordingly. Please note that you may be liable for damages,
including costs and attorneys’ fees, under the DMCA if you knowingly materially misrepresent that material on the Site
infringes upon your copyright.`}</p>
    <p>{`For clarity, only DMCA notices as described above should go to the Copyright Agent. You acknowledge that if you fail to
comply with all of the requirements of this policy, your DMCA notice may not be valid and we may not remove the
infringing material.`}</p>
    <p>{`NOTHING IN THIS SECTION IS INTENDED TO EXPAND OR SUPPLEMENT THE LEGAL RIGHTS, PROCEDURES AND REMEDIES AUTHORIZED AND
GRANTED UNDER THE DMCA AND WE DO NOT REPRESENT ANY RELATED UNDERTAKING BY BUSINESS.COM NOT OTHERWISE EXPRESSLY REQUIRED
BY APPLICABLE LAW.`}</p>
    <p>{`1.2 `}<u>{`How to File a DMCA Counter Notice`}</u>{`. The process for counter-notifications is governed by Section 512(g) of the
Digital Millennium Copyright Act:
`}<a parentName="p" {...{
        "href": "http://www.copyright.gov/legislation/dmca.pdf"
      }}>{`http://www.copyright.gov/legislation/dmca.pdf`}</a>{`. If you believe that your
material has been removed improperly, you may file a counter notification. To file an official counter notification with
us, you must provide a written communication that sets forth the items specified below. Please note that under Section
512(f) of the Copyright Act, any person who knowingly materially misrepresents that material or activity was removed or
disabled by mistake or misidentification may be subject to liability. Please also be advised that we enforce a policy
that provides for the termination in appropriate circumstances of users who are repeat infringers. Accordingly, if you
are not sure whether certain material infringes the copyrights of others, we suggest that you first contact an attorney.`}</p>
    <p>{`To expedite our ability to process your counter notification, please use the following format:`}</p>
    <ul>
      <li parentName="ul">{`Identify the specific URLs of material that Business.com has removed or to which Business.com has disabled access.`}</li>
      <li parentName="ul">{`Provide your full name, address, telephone number, and email address, and, if applicable, the username of your Business.com account.`}</li>
      <li parentName="ul">{`Provide a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located (or any judicial district in which Business.com may be found if your address is outside of the United States), and that you will accept service of process from the person who provided notification under subsection (c)(1)(C) or an agent of such person.`}</li>
      <li parentName="ul">{`Include the following statement: “I swear, under penalty of perjury, that I have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled.”`}</li>
      <li parentName="ul">{`Sign the notice.`}</li>
      <li parentName="ul">{`Send the written communication to us by one of the following means:`}</li>
    </ul>
    <p>{`Business.com Copyright Agent
200 Fifth Avenue
Second Floor
Waltham, MA 02451`}</p>
    <p>{`Or send an email to: `}<a parentName="p" {...{
        "href": "mailto:copyright@business.com"
      }}>{`copyright@business.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      